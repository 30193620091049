@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.Whats {
    border-radius: 1px solid red;
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 1;
    bottom: 5em;
    right: 4%;
    cursor: pointer;

    &:hover {
        transform: scale(1.4);
    }

    & a img {
        width: 100%;
        height: 100%;
    }
}

.Navbar1 {
    // @include border-test(blue);
    @include flexbox(space-between);
    overflow-x: hidden;
    width: 100%;
    max-height: 200px;
    padding-left: 5vw;
    padding-right: 5vw;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.575);
    z-index: 999;
}

.Navbar2 {
    // @include border-test(blue);
    @include flexbox(space-between, flex-start);
    overflow-x: hidden;
    width: 100vw;

    & .Navbar__img {
        width: 90%;

    }

    & .Navbar__menu2 {
        @include flexbox(center, flex-start);
        width: 10%;
        height: 5px;
        margin-top: 20px;
    }

}


.Navbar__logo {
    // @include border-test(red);
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    width: auto;
    height: 80%;
    overflow: hidden;

    & img {
        width: 100%;
        height: 100%;
        max-height: 100%;
    }
}

.Navbar__menu {
    // @include border-test(black);
    @include flexbox(flex-end);
    width: 5%;
    height: 5px;

}

@media screen and(max-width: 1350px) {
    .Navbar__logo {
        width: 30%;
    }
}

@media screen and(max-width: 1200px) {
    .Navbar__logo {
        width: 40%;
    }

}


@media screen and(max-width: 800px) {
    .Navbar__menu {
        width: 20%;
    }

    .Navbar {
        max-height: 110px;
        padding: 0 2vw;
    }

    .Navbar__logo {
        width: 60%;
    }
}

@media screen and(max-width: 500px) {

    .Navbar__logo {
        width: 90%;
    }
}