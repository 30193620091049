@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.Footer {
    // @include border-test(blue);
    @include flexbox(center, flex-start);
    width: 100vw;
    overflow-x: hidden;
    padding: 10px 0 10px 0;
    background-color: #C4C4C4;
    border-top: solid 6px #255429;
    border-bottom: solid 6px #255429;

    & .Footer__menu {
        // @include border-test(blue);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;

        & ul {
            @include flexbox(center);
            list-style: none;

            & li {

                padding-right: 25px;
                margin-right: 25px;
                border-right: 2px solid white;

                & a {
                    text-decoration: none;
                    color: #255429;
                    font-family: $font-roboto;
                    font-weight: 500;
                    line-height: 50px;
                    font-size: 1.2rem;
                }
            }

            & li:nth-of-type(4) {
                border-right: none;
            }
        }
    }

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        a {
            text-decoration: none;
        }

        p {
            color: #255429;
            margin: 0;

            span {
                margin-right: 10px;
            }
        }

        .adv {
            font-size: smaller;
            color: #535353;
        }
    }

    .somaD {
        height: 15vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 2em;

        a {
            text-decoration: none;
            color: #255429;
            font-weight: bolder;
        }
    }

    & .Footer__others {
        // @include border-test(blue);
        margin-top: 40px;
        margin-left: 80px;
        margin-right: 20px;

        & li {
            list-style: none;

            & a {
                color: white;
                text-decoration: none;
                font-family: $font-roboto;
                font-weight: 500;
                line-height: 20px;
                font-size: 1.2rem;
                font-style: normal;
            }
        }

        & .Footer__others-social {
            margin-top: 10px;

            @include flexbox(space-between);
        }
    }
}

@media screen and(max-width: 1000px) {
    .Footer {

        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        align-items: initial;
        justify-content: initial;
        justify-items: center;

        & .Footer__menu {
            @include flexbox(center, center, column);
            width: 100%;
            grid-column: 1/2;
            grid-row: 1/2;

            & ul {
                display: block;
                text-align: center;

                & li {
                    border: none;
                }
            }
        }

        & .Footer__others {
            padding-top: 30px;
            margin: 0;
            @include flexbox(center, center, column);
        }

        & .Footer__others-social {
            grid-column: 1/2;
            grid-row: 2/3;

            & img {
                margin: 0 20px;
                margin-top: 30px;
            }
        }
    }

}