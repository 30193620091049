@import '../../sassUtils/vars.scss';
@import '../../sassUtils/mixins.scss';


.Contact{
 // border: 1px solid red;
 width: 100vw;
 overflow: hidden;
 display: grid;
 grid-template-columns: 100%;
 grid-template-rows: auto auto auto;

 & .Contact__img{
  // border: 1px solid blue;
  grid-column: 1/2;
  grid-row: 1/2;
  // padding: 0 10%;
  & img{
   width: 100%;
  }
 }
 
 & .Contact__form{
  // border: 1px solid blue;
  grid-column: 1/2;
  grid-row: 2/3;
  padding: 1% 5%;

  & h1{
   font-family: $font-monserrat;
   font-weight: 600;
   font-size: 2rem;
   margin: 0;
   line-height: 50px;
  }

  & p{
   font-family: $font-monserrat;
   font-weight: 500;
   font-size: 1.3rem;
   color: #707070;
   margin: 0;
   margin-bottom: 20px;
  }

  & .Contact__form-container{
   // border: 1px solid grey;
   width: 100%;
   display: grid;
   grid-template-columns: 47% 47%;
   grid-column-gap: 6%;
   grid-template-rows: auto auto auto;
   grid-row-gap: 20px;

   & .Contact__form-name{
    // border: 1px solid red;
    @include flexbox(center,flex-start,column);
    & span{
     font-family: $font-raleway;
     line-height: 50px;
     font-size: 1.5rem;
    }

    & input{
     all: unset;
     width: 90%;
     height: 60px;
     outline: none;
     font-size: 1.2rem;
      padding: 0 10px;
      color: black;
      box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
     &::placeholder{
      color: #BABABA;
      font-size: 1.2rem;

     }
    }
   }

   & .Contact__form-email{
    // border: 1px solid red;
    @include flexbox(center,flex-start,column);
    & span{
     font-family: $font-raleway;
     line-height: 50px;
     font-size: 1.5rem;
    }

    & input{
     // border-color: #707070;
     all: unset;
     width: 90%;
     height: 60px;
     outline: none;
     font-size: 1.2rem;
      padding: 0 10px;
      color: black;
      box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.5);
      border-radius: 15px;
     &::placeholder{
      color: #BABABA;
      font-size: 1.2rem;

     }
    }
   }

   & .Contact__form-message{
    // border: 1px solid red;
    grid-column: 1/3;
    @include flexbox(center,flex-start,column);
    & span{
     font-family: $font-raleway;
     line-height: 50px;
     font-size: 1.5rem;
    }

    & textarea{
    // border: 1px solid red;
    all: unset;
     width: 95%;
     height: 150px;
     outline: none;
     font-size: 1.2rem;
      padding: 10px 10px;
      font-family: $font-raleway;
     line-height: 50px;
      box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
      color: #BABABA;

     
    }
   }

  }
 }

 & .Contact__button{
  // border: 1px solid blue;
  all: unset;
  margin: 0 35%;
  grid-column: 1/3;
  grid-row: 3/4;
  width: 150px;
  height: 50px;
  border-radius: 20px;
  border: 2px solid $color-greenBig;
  font-family: $font-raleway;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0 5%;
 }

}

@media screen and(max-width: 600px){
 .Contact .Contact__form{
  padding-left:0 ;
  padding-right: 0;
 }
 .Contact .Contact__form h1{
  font-size: 1.5rem;
  text-align: center;
 }

 .Contact .Contact__form p{
  font-size: 1rem;
  text-align: center;
 }

 .Contact .Contact__form .Contact__form-container{
  grid-template-columns: auto;
  grid-template-rows: auto auto auto auto;
  & *{
   all: unset;
  }
 }

 .Contact .Contact__form .Contact__form-container .Contact__form-name{
  grid-column: 1/2;
  grid-row: 1/2;
  @include flexbox(center,center,column);

  & span{
   font-size: 1.2rem;
  }
  & input{
   width: 50%;
  }
 }

 .Contact .Contact__form .Contact__form-container .Contact__form-email{
  grid-column: 1/2;
  grid-row: 2/3;
  @include flexbox(center,center,column);

  & span{
   font-size: 1.2rem;
  }

  & input{
   width: 50%;
  }
 }

 .Contact .Contact__form .Contact__form-container .Contact__form-message{
  grid-column: 1/2;
  grid-row: 3/4;
  @include flexbox(center,center,column);
  & span{
   font-size: 1.2rem;
  }
  & textarea{
   width: 50%;
  }
 }

 .Contact .Contact__form .Contact__form-container .Contact__button{
  width: 150px;
  height: 50px;
  border-radius: 20px;
  border: 2px solid $color-greenBig;
  font-family: $font-raleway;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0 5%;
  margin: 0 auto;
 }


}