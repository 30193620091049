@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.Home {
  // @include border-test(blue);
  width: 100vw;
  overflow-x: hidden;
}


.Home__teacher-in {
  // @include border-test(purple);
  width: 90vw;
  margin: 2vh 5vw;
}

.Home__teacher-in-title {
  // @include border-test(purple);
  margin: 0;
  margin-bottom: 20px;
  font-family: $font-raleway;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 1.3rem;
}

.Home__teacher-in-list {
  // @include border-test(purple);
  display: grid;
  grid-template-columns: repeat(6, 15%);
  grid-template-rows: 100%;
  grid-column-gap: 2%;

  & .Home__teacher-in-item {

    // @include border-test(purple);
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.Home__main {
  // border: 1px solid blue;
  width: 100vw;
  display: grid;
  height: auto;
  grid-template-columns: 25% 75%;

}

.Home__main-img {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  grid-column: 1/2;
}

.Home__main-right {
  // border: 1px solid red;
  grid-column: 2/3;
  @include flexbox(center, flex-start, column);
  padding-top: 8%;
}

.Home__main-right-profile {
  // border: 1px solid black;
  width: 70%;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  margin-left: 250px;
}

.Home__main-right-profile-img {
  // border: 1px solid black;
  grid-column: 1/2;
  position: relative;
  padding-bottom: 10px;

  & .Home__main-right-caro {
    width: 90%;
    height: 100%;
  }

  & .Home__main-right-profile-bottom {
    // border: 1px solid blue;
    position: absolute;
    width: 50%;
    height: 50%;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgba(37, 84, 41, 0.7);
    border-radius: 0px 0px 20px 0px;
  }

  & .Home__main-right-profile-social {
    // border: 1px solid black;
    position: absolute;
    width: 20%;
    height: 80%;
    left: -30%;
    top: 10%;
    @include flexbox(center, center, column);

    & a {
      margin-bottom: 5px;
    }
  }

  & .Home__main-right-profile-left {
    // border: 1px solid black;
    position: absolute;
    width: 220%;
    height: 120%;
    left: -165%;
    top: -10%;
    z-index: -1;

    background: #f4f6f4;
    border-radius: 0px 30px 30px 0px;
  }
}

.Home__main-right-profile-details {
  // border: 1px solid black;
  padding-left: 20px;
  grid-column: 2/3;
  @include flexbox(center, flex-start, column);

  & a {
    text-decoration: none;
  }

  & .Home__main-right-profile-data {
    // border: 1px solid black;
    font-family: $font-raleway;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left: 5px solid #255429;

    & p {
      color: #838C8D;
      font-size: 20px;
    }
  }
}

.trayectG {
  margin-left: auto;
  margin-right: auto;
}

.Home__decorate-bottom {
  // border: 1px solid red;
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50% 50%;

  & .Home__decorate-bottom-left {
    // border: 1px solid red;
    grid-column: 1/2;
    background-color: #C4C4C4;
  }

  & .Home__decorate-bottom-right {
    // border: 1px solid red;
    grid-column: 2/3;
    background-color: #255429;

  }
}

@media screen and (max-width: 1250px) {
  .Home__main-right-profile {
    margin-left: 200px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .Home__main-right-profile {
    margin-left: 100px;
  }
}

@media screen and(max-width: 850px) {
  .Home__main-right {
    margin-top: 50px;
  }

  .Home__teacher-in-title {
    font-size: 1rem;
  }

  .Home__teacher-in-list {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    grid-template-rows: 100%;
    grid-column-gap: 2%;

    & .Home__teacher-in-item:nth-of-type(4) {
      display: none;
    }

    & .Home__teacher-in-item:nth-of-type(5) {
      display: none;

    }

    & .Home__teacher-in-item:nth-of-type(6) {
      display: none;

    }
  }

  .Home__main {
    grid-template-columns: 100%;
    margin-top: 100px;
  }


  .Home__main-img {
    display: none;

  }

  .Home__main-right {
    grid-column: 1/2;
    align-items: flex-start;
    padding: 0;
    padding: 10%;
    padding-right: 5px;
    padding-left: 5px;

  }

  .Home__main-right-profile {
    width: 70%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.5fr;
    margin-left: 0;
    // padding-left: 5vw;
    // padding-right: 5vw;
  }

  .Home__main-right-profile-img {
    grid-column: 1/2;
    grid-row: 1/2;
    margin-left: 40px;
  }

  .Home__main-right-profile-details {
    margin-top: 45px;
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .Home__main-right-profile-img .Home__main-right-profile-left {
    width: 150%;
    left: -100%;
  }

  .Home__main-right-profile-img .Home__main-right-profile-social {
    left: -25%;
  }
}

@media screen and(max-width: 450px) {


  .Home__main-right-profile {
    width: 85%;
    text-align: end;
  }

  .Home__main-right-profile-img .Home__main-right-profile-social {
    left: -5%;
  }

  .Home__main-right-profile-img .Home__main-right-caro {
    width: 80%;
  }

  .Home__main-right-profile-img .Home__main-right-profile-bottom {
    right: -5%;
  }


  .Home__main-right-profile-details .Home__main-right-profile-data {
    text-align: initial;
    font-size: 1.1rem;
    // margin-bottom: 28px;
  }

  .Home__main-right-profile-img .Home__main-right-profile-left {
    left: -80%;
  }

  .Home__main-right-profile-img {
    margin-left: 0px;
  }
}