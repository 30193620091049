@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.Button {
  @include flexbox();
  padding: 5px 20px;
  // width: auto;
  max-width: 185px;
  // height: 40px;
  border-radius: 8px;
  border: 2px solid $color-greenBig;
  cursor: pointer;


  & p {
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: $font-raleway;
    font-weight: bold;
    font-size: 1.2rem;
    color: black;
    text-align: center;
  }


}