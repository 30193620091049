@import '../../sassUtils/vars.scss';
@import '../../sassUtils/mixins.scss';

.About {
  // border: 1px solid blue;
  overflow-x: hidden;
  width: 90vw;
  padding: 5% 5%;
  // position: relative;
}

.mobile-intro {
  display: none;
}

.About-container {
  // border: 1px solid red;
  width: 100%;
  //  display: grid;
  //  grid-template-columns: 55% 40%;
  //  grid-template-rows: auto auto;
  //  grid-column-gap: 5%;
  //  grid-row-gap: 50px;

  & .About__img {
    // border: 1px solid blue;
    grid-column: 1/2;
    grid-row: 1/2;

    & div {

      width: 95%;
      position: relative;
      padding-left: 5%;

      & img {
        width: 100%;
      }

      & div {
        width: 50%;
        height: 50%;
        position: absolute;
        bottom: -10%;
        left: 0;
        z-index: -1;
        background: rgba(37, 84, 41, 0.6);
        border-radius: 0px 0px 0px 20px;
      }
    }

  }

  & .About__littleDescription {
    // border: 1px solid black;
    margin: auto;
    // padding: 0 10%;
    width: 30vw;
    margin-bottom: 70px;

    & div {
      border-left: 10px solid #587454;
      padding-left: 20px;

      & h2 {
        font-family: $font-raleway;
        font-weight: bold;
        font-size: 2rem;
      }

      & p {
        font-family: $font-monserrat;
        font-weight: normal;
        font-size: 1.5rem;
        color: #707070;
      }

    }

  }

  .alter {
    color: #587454;
  }

  & .About__details {
    // border: 1px solid black;
    padding-left: 20%;
    grid-column: 1/3;
    grid-row: 2/3;

    & h2 {
      font-family: $font-raleway;
      font-weight: bold;
      font-size: 2rem;
    }

    & p {
      font-family: $font-monserrat;
      font-weight: 500;
      color: #707070;
      font-size: 1.4rem;
    }
  }

}

@media screen and(max-width: 1500px) {
  .About {
    & .About-container {
      & .About__img {
        & img {}
      }

      & .About__littleDescription {
        & div {
          & h2 {
            font-size: 1.7rem;
          }

          & p {
            font-size: 1.2rem;
          }
        }
      }

      & .About__details {
        padding-left: 10%;

        & h2 {
          font-size: 1.7rem;
        }

        & p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

@media screen and(max-width: 1050px) {
  .About {
    & .About-container {
      grid-column-gap: 0;
      grid-template-columns: repeat(2, 50%);

      & .About__img {
        & img {}
      }

      & .About__littleDescription {
        & div {
          & h2 {
            font-size: 1.4rem;
          }

          & p {
            font-size: 1rem;
          }
        }
      }

      & .About__details {
        padding-left: 5%;

        & h2 {
          font-size: 1.5rem;
        }

        & p {
          font-size: 1rem;
        }
      }
    }
  }
}


@media screen and(max-width: 800px) {
  .mobile-intro {
    display: block;
  }

  .About {
    & .About-container {
      grid-column-gap: 0;
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto;

      & .About__img {
        grid-column: 1/2;
        grid-row: 1/2;


        & img {}
      }

      & .About__littleDescription {
        grid-column: 1/2;
        grid-row: 2/3;
        width: 100%;

        & div {
          & h2 {
            font-size: 1.4rem;
          }

          & p {
            font-size: 1rem;
          }
        }
      }

      & .About__details {
        grid-column: 1/2;
        grid-row: 3/4;
        padding-left: 5%;

        & h2 {
          font-size: 1.5rem;
        }

        & p {
          font-size: 1rem;
          text-align: justify;

          & span {
            color: #587454;
            font-weight: bold;
          }
        }

      }
    }
  }
}