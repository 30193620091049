@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.Services {
  // @include border-test(red);
  width: 90%;
  height: auto;
  padding: 4% 5% 10% 5%;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto auto;
  position: relative;

  & .Services__selected-little {
    border-radius: 20px;
    width: 60%;
    height: auto;
    position: absolute;
    top: 20vh;
    background-color: rgb(255, 255, 255);
    box-shadow: -7px -1px 30px 0px rgba(129, 131, 131, 1);
    @include flexbox(center, center, column);
    padding: 5% 10%;

    & h1 {
      font-family: $font-raleway;
      font-size: 1.8rem;
      font-weight: bold;
    }

    & div {
      color: #818383;
      font-family: $font-roboto;
      font-size: 1rem;
    }

    & .Services-cross {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  & .Services__selected {
    // border: 1px solid blue;
    grid-column: 1/3;
    grid-row: 2/3;
    text-align: justify;
    margin-right: 1em;
    // margin-bottom: 200px;

    & .Services__selected-true {
      margin-top: 50px;
      // border: 1px solid blue;
      display: grid;
      grid-template-columns: 60% 40%;
      grid-template-rows: 300px;

      & .Services__selected-details {
        // border: 1px solid red;
        // padding: 5% 10%;
        height: 300px;

        & .Services__selected-title {
          // border: 1px solid red;
          padding-left: 5%;
          @include flexbox();

          & div {
            width: 20%;
            height: 8px;
            background-color: #255429;
            // margin: 0 15px;
          }

          & h1 {
            // border: 1px solid red;
            width: 60%;
            margin-left: 10%;
            font-family: $font-raleway;
            font-weight: bold;
            font-size: 1.7rem;
            margin-bottom: 5px;
            margin-top: 5px;
          }
        }

        & .Services__selected-text {
          // border: 1px solid red;
          color: #818383;

          & ul {


            & li {
              margin-bottom: 25px;
            }
          }
        }
      }

      & .Services__selected-img {
        // border: 1px solid blue;
        position: relative;

        & img {
          //  position: absolute;
          //  top: 0;
          //  right: 0;
          width: 379.3px;
          height: 268.78px;
        }

        & div {
          // border: 1px solid blue;

          position: absolute;
          left: 0;
          bottom: 0;
          width: 90%;
          height: 70%;
          border-bottom-left-radius: 20px;
          background-color: #255429;
          opacity: 0.5;
          z-index: -1;
        }
      }
    }

    .meditacionC {
      display: flex;
    }

  }

  .meditacionC2 {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .seccionHome {
    // border: black 1px solid;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    margin-left: 30px;

    div {
      border: solid 3px #255429;
      width: 150px;
      margin: 0 15px;
    }
  }

  & .Services__title {
    // border: 1px solid black;
    height: auto;
    grid-column: 1/2;
    padding: 20% 0;
    padding-left: 15%;
    position: relative;

    & div {
      // border: 1px solid green;
      font-size: 3rem;
      line-height: 80px;

    }

    & .Services__decorate {
      width: 100px;
      height: 6px;
      background: #255429;
      // position: absolute;
      // right: 0;
    }
  }

  & .Services__icons {
    // border: 1px solid black;
    grid-column: 2/3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    & .Services__icons-1 {
      // border: 1px solid black;
      grid-column: 1/2;
      @include flexbox(center, flex-start);
      padding: 3%;

      & img {
        // border: 1px solid black;
        cursor: pointer;
        max-width: 95%;
      }

      & .Services__icons-rotate {
        // border: 1px solid red;
        position: relative;
        @include flexbox();

        & .Services__icons-rotate-fondo {
          // border: 1px solid red;
          position: absolute;
          width: 40%;
          bottom: 35%;
        }

      }
    }

    & .Services__icons-2 {
      // border: 1px solid black;
      grid-column: 2/3;
      @include flexbox(center, flex-end);
      padding: 5%;

      & img {
        // border: 1px solid black;
        max-width: 95%;
        cursor: pointer;
      }

      & .Services__icons-rotate {
        // border: 1px solid red;
        position: relative;
        @include flexbox();

        & .Services__icons-rotate-fondo {
          // border: 1px solid red;
          position: absolute;
          width: 40%;
          bottom: 35%;
        }

      }
    }

    & .Services__icons-3 {
      // border: 1px solid black;
      grid-column: 3/4;
      @include flexbox(center, flex-start);
      padding: 5%;

      & img {
        // border: 1px solid black;
        max-width: 95%;
        cursor: pointer;
      }

      & .Services__icons-rotate {
        // border: 1px solid red;
        position: relative;
        @include flexbox();

        & .Services__icons-rotate-fondo {
          // border: 1px solid red;
          position: absolute;
          width: 40%;
          bottom: 35%;
        }

      }
    }
  }
}

@media screen and(max-width: 830px) {

  // .Services .Services__selected{

  //  display: none;
  // }

}

@media screen and(max-width: 830px) {

  .Services {
    // border: 1px solid black;
    grid-template-columns: 1fr;
    // grid-template-rows: 0.2fr 1fr;
    padding: 0;
    width: 100%;
    margin-bottom: 100px;
  }

  .Services .Services__title {
    grid-column: 1/2;
    grid-row: 1/2;
    transform: none;
    padding: 0;
    padding-left: 5%;
    margin-bottom: 15px;
  }

  .Services .Services__icons {
    // border: 1px solid red;
    grid-column: 1/2;
    grid-row: 2/3;
    @include flexbox(center, center, column);
  }

  .Services .Services__icons {
    // position: relative;
    display: none;
  }

  .Services__icons-decorate {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #255429;
    right: 0;
    z-index: -1;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    opacity: 0.5;
  }

  .meditacionC {
    // border: 1px solid blue;
    display: flex;
    flex-direction: column;
  }

  .movil {
    img {
      width: 361px;
    }
  }

  .Services__selected-text {
    ul {
      margin: auto;
      padding-left: 20px;
      padding-right: 10px;
      text-align: justify;
    }
  }

  .seccionHome {
    // border: black 1px solid;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;

    div {
      border: solid 3px #255429;
      width: 150px;
    }

  }

}


@media screen and(max-width: 620px) {
  .Services .Services__selected-little {
    width: 90%;
    padding: 2% 2%;
  }
}