@import '../../sassUtils/vars.scss';
@import '../../sassUtils/mixins.scss';


.FloatMenu {
  // border: 1px solid red;
  width: auto;
  height: 50px;

  @include flexbox();
  position: fixed;
  z-index: 1000;

  & .FloatMenu__img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }

}

.FloatMenu__block {
  // border: 1px solid red;
  // background-color: white;
  position: fixed;
  top: 0;
  right: 10px;
  display: none;
}


.FloatMenu-active {
  // border: 1px solid red;
  // display: none;
}

.FloatMenu__block-active {
  // border: 1px solid red;
  background-color: white;
  width: 25vw;
  display: grid;
  grid-template-columns: 85% 15%;
  grid-template-rows: auto auto auto;
  padding: 20px 10px;
  border-bottom-left-radius: 50px;
  box-shadow: -7px 7px 5px 0px rgb(160, 160, 160);
}

.FloatMenu__block-logo {
  // border: 1px solid blue;
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  justify-content: flex-start;

  & img {
    width: 75%;
  }
}

.FloatMenu__block-cross {
  // border: 1px solid green;
  cursor: pointer;
  grid-column: 2/3;
  grid-row: 1/2;
  @include flexbox();
}

.FloatMenu__block-nav {
  // border: 1px solid black;
  grid-column: 1/3;
  grid-row: 2/3;
}

.FloatMenu__block-menu {
  // border: 1px solid black;
  width: 50%;
  list-style: none;
  padding: 0;
  margin-top: 30px;
}

.FloatMenu__block-menu-item {
  // border: 1px solid rgb(238, 48, 48);
  margin: 0;
  padding: 20px 0;
  padding-left: 10px;

  border-bottom: 2px solid $color-greySoft;

  & a {
    @include flexbox(space-between);
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-family: $font-roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;

    & .FloatMenu__block-menu-triangle {
      width: 15px;

    }
  }
}

.imgLang {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.iconAdress {
  width: 15px !important;
}

.FloatMenu__block-details {
  // border: 1px solid black;
  padding-left: 8px;
  grid-column: 1/3;
  grid-row: 3/4;
  margin-bottom: 5px;
  @include flexbox(space-between);

  & .FloatMenu__block-details-left {
    // border: 1px solid black;
    max-width: 80%;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  & .FloatMenu__block-details-right {
    // border: 1px solid black;
    width: auto;
    height: auto;
    display: block;
    padding: 10px;

    & div:nth-of-type(1) {
      // border: 1px solid red;
      line-height: 30px;
      color: black;
      font-size: $font-roboto;
      font-weight: 500;
      font-size: 1rem;
    }

    & div:nth-of-type(2) {
      // border: 1px solid red;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      @include flexbox();
      background-color: #F1F1F1;
      color: black;
      font-size: $font-roboto;
      font-weight: bold;
      font-size: 1.2rem;
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        color: #707070;
      }
    }
  }
}

.FloatMenu__block-details-left {

  p,
  span {
    font-size: smaller;
    color: #707070;
    margin: 5px;
  }

  a {
    text-decoration: none;
    color: #255429;
  }
}

@media screen and(max-width: 1250px) {
  .FloatMenu__block-active {
    width: 35vw;
  }

  .FloatMenu__block-logo img {
    width: 85%;
  }
}

@media screen and(max-width: 1000px) {
  .FloatMenu__block-active {
    width: 45vw;
  }

}

@media screen and(max-width: 750px) {
  .FloatMenu__block-active {
    width: 60vw;
  }

  .FloatMenu__block-menu-item a {
    font-size: 1rem;
  }

  .FloatMenu__block-nav {
    margin-bottom: 40px;
  }
}

@media screen and(max-width: 550px) {
  .FloatMenu__block-active {
    width: 80vw;
  }

  .FloatMenu__block-nav {
    margin-bottom: 0px;
  }
}

@media screen and(max-width: 400px) {
  .FloatMenu__block-active {
    width: 90vw;
  }

  .FloatMenu__block-logo img {
    width: 95%;
  }
}