@import "../../sassUtils/mixins.scss";
@import "../../sassUtils/vars.scss";

.vermas {
  width: 150px;
}

.Hero {
  // border: 1px solid red;
  margin-top: 150px;
  width: 100vw;
  display: grid;
  grid-template-columns: 85% 15%;
  grid-template-rows: auto;
  position: relative;

  // overflow: hidden;
  // padding-bottom: 50px;
  & .Hero-triangle {
    position: absolute;
    top: -70px;
    width: 30%;
  }

  & .Hero-decorate-little {
    // border: 1px solid red;]
    width: 95%;
    height: 25%;
    position: absolute;
    bottom: -25%;
    right: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: 60% 40%;
    opacity: 1;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    & .Hero-decorate-little-left {
      background-color: #255429;
      @include flexbox();

      & p {
        color: white;
        width: 90%;
        font-size: 1rem;
        font-family: $font-raleway;
      }
    }

    & .Hero-decorate-little-right {
      background-color: white;
      @include flexbox();

      & img {
        height: 90%;
      }
    }
  }

  & .Hero-decorate {
    // border: 1px solid red;
    width: 95%;
    height: 25%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: 60% 40%;
    opacity: 0.8;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    & .Hero-decorate-left {
      background-color: #255429;
      @include flexbox();

      & p {
        color: white;
        width: 90%;
        font-size: 1.2rem;
        font-family: $font-raleway;
      }
    }

    & .Hero-decorate-right {
      background-color: #C4C4C4;
      @include flexbox();

      & img {
        height: 90%;
      }
    }
  }
}

.vermas {
  width: 150px;
}

.Hero__left {
  // border: 1px solid red;
  position: relative;
  // margin-bottom: 100px;
  grid-column: 1/2;
  grid-row: 1/2;
  padding: 50px 50px 170px 10%;
  font-family: $font-roboto;

  & a {
    text-decoration: none;
  }

  & .Hero-hand {
    position: absolute;
    width: 45%;
    height: 130%;
    right: 0;
    bottom: 0;
  }

  & .Hero__left-title {
    // border: 1px solid blue;
    width: 50%;
    font-size: 2.4rem;
  }

  & .Hero__left-description {
    // border: 1px solid blue;
    width: 40%;
    font-size: 1.3rem;
    font-weight: 500;
  }
}

.Hero__right {
  // border: 1px solid blue;
  grid-column: 2/3;
  grid-row: 1/2;
  @include flexbox(space-around, center, column);
  // padding-left: 50px;
  padding-bottom: 50%;

  & div .Hero__rigth-title {
    margin-top: 5px;
    font-family: $font-raleway;
    font-weight: 700;
  }
}

.Hero__right {
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

@media screen and(max-width: 1270px) {
  .Hero__left .Hero-hand {
    width: 45%;
  }

  .Hero__left .Hero__left-title {
    font-size: 1.8rem;
    width: 50%;
  }

  .Hero__left .Hero__left-description {
    font-size: 1rem;
    width: 40%;
  }

  .Hero__right {
    padding-bottom: 100%;
    padding-left: 10px;
  }
}

@media screen and(max-width: 1050px) {
  .Hero__left .Hero__left-title {
    font-size: 1.5rem;
    width: 50%;
  }

  .Hero__left .Hero__left-description {
    font-size: 1rem;
    width: 50%;
  }

  .Hero__left {
    padding-top: 30px;
    // padding-bottom: 100px;
  }

  .Hero .Hero-triangle {
    width: 35%;
  }
}

@media screen and (max-width: 850px) {
  .Hero__left .Hero-hand {
    display: none;
  }

  .Hero {
    grid-template-rows: auto auto;
    grid-column-gap: 5px;
    grid-template-columns: 80% 20%;
    background-color: #C4C4C4;
    padding-bottom: 10px;
  }

  .Hero .Hero-triangle {
    display: none;
  }

  .Hero .Hero-decorate {
    display: none;
  }

  .Hero__left {
    background-color: white;
    grid-row: 2/3;
    @include flexbox(center, center, column);
    padding-bottom: 50px;
    border-bottom-right-radius: 15px;
  }

  .Hero__right {
    background-color: white;
    grid-row: 2/3;
    padding-bottom: 20px;
    border-bottom-left-radius: 15px;
    padding-left: 0;
    align-items: center;
    padding-top: 20px;
  }

  .Hero__img-responsive {
    width: 100vw;
  }

  .Hero__left .Hero__left-title {
    width: 80%;
    font-size: 1.2rem;
  }

  .Hero__left .Hero__left-description {
    width: 80%;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 850px) {
  .Hero {
    grid-template-columns: 65% 35%;
    margin-top: 80px;
  }

  .Hero__left {
    padding-top: 5px;
    padding-left: 3%;
    padding-right: 3%;

  }

  .Hero__left .Hero__left-description {
    margin-bottom: 20px;
  }

  .Hero .Hero-decorate-little .Hero-decorate-little-left p {
    font-size: 0.8rem;
  }

}